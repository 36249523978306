* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--color-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: var(--color-grey);
  padding: var(--size-s);
}

h1 {
  color: var(--color-nitor-blue);
  font-size: 3rem;
}

a {
  color: var(--color-grey);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  font-size: var(--font-size-small);
}

:root {
  --color-grey: #ADA9A1;
  --color-white: #ffffff;
  --color-black: #26273A;
  --color-nitor-blue: #6056EB;
  --color-light-blue: #6DC4E2;
  --color-pink: #FFD1C1;
  --border-color: var(--color-black);
  --background-color: var(--color-white);

  --size-xs: .25rem;
  --size-s: .5rem;
  --size-m: 1rem;
  --size-l: 1.5rem;
  --size-xl: 2rem;
  --size-2-xl: 2.5rem;

  --border-radius-small: 6px;
  --border-radius-large: 20px;

  --font-size-small: .75rem;
  --font-size-normal: 1rem;

  --z-index-top: 2;
  --z-index-mid: 1;

  --transition-quick: 100ms;
  --transition-slow: 300ms;
  --ease-function: ease-in-out;

  --transition-list-width: 10rem;
}
