@import "../../mixins";

.footer {
  display: flex;
  margin-top: var(--size-xl);
  font-size: var(--font-size-small);
  color: var(--color-grey);

  a {
    margin-left: var(--size-xs);
  }

  .divider {
    margin-left: var(--size-xs);
  }

  @include for-mobile {
    display: none;
  }
}
