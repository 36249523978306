@import "mixins";

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: calc(var(--size-2-xl) * 2);

  .mobile-error-message {
    display: none;
    margin: var(--size-s);

    @include for-mobile {
      display: block;
    }
  }
}

.header-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 90rem;
}

.main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 90rem;
  position: relative;
  width: 90%;

  @include for-desktop {
    height: 35rem;
    margin-top: var(--size-l);
  }

  @include for-mobile {
    display: none;
  }
}

.content-container {
  width: calc(100% - var(--transition-list-width));
  padding: 3rem 2rem 5rem 2rem;
  box-shadow: 5px 5px 32px 3px var(--color-grey);
  background-color: var(--color-white);
  border-radius: var(--border-radius-large);
  z-index: var(--z-index-mid);
}

.content {
  display: flex;
  height: 100%;

  @include for-tablet {
    flex-direction: column;
  }
}

.selectors {
  padding-right: var(--size-2-xl);

  @include for-desktop {
    margin-right: var(--size-2-xl);
    border-right: 1px solid var(--color-grey);
  }

  .property-selector {
    margin-bottom: var(--size-xl);
  }
}
