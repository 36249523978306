.value-slider {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--size-xl);

  span {
    font-size: var(--font-size-small);
  }

  &-name {
    margin-bottom: var(--size-m);
  }

  &-range {
    -webkit-appearance: none;
    width: 100%;
    height: var(--size-xs);
    border-radius: var(--border-radius-large);
    background: var(--color-black);
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: var(--size-l);
      height: var(--size-l);
      border-radius: 50%;
      background: var(--color-nitor-blue);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: var(--size-l);
      height: var(--size-l);
      border-radius: 50%;
      background: var(--color-nitor-blue);
      cursor: pointer;
    }
  }

  &-input-field-container {
    margin-top: var(--size-m);
  }
}
