@import "../../mixins";

.help-text {
  margin-left: auto;
  margin-right: var(--size-l);
  position: relative;

  &:hover {
    .info-block {
      display: flex;
    }
  }

  .hover-block {
    display: flex;
    align-items: center;
    cursor: help;
    margin-bottom: var(--size-l);

    @include for-desktop {
      margin-bottom: 0;
    }
  }

  .info-block {
    flex-direction: column;
    display: none;
    position: absolute;
    z-index: var(--z-index-top);
    background-color: var(--color-nitor-blue);
    color: var(--color-white);
    border: 1px solid var(--color-pink);
    padding: var(--size-m);
    width: 80vw;
    right: 0;
  }

  span {
    font-size: var(--font-size-small);
    margin-right: var(--size-s);
  }

  svg {
    color: var(--color-grey);
  }
}
