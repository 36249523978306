@mixin for-mobile {
  @media only screen and (max-width: 799px) {
    @content;
  }
}

@mixin for-tablet {
  @media only screen and (min-width: 800px) and (max-width: 1199px)  {
    @content;
  }
}

@mixin for-desktop {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}
