.add-new-transition {
  border: none;
  font-size: var(--font-size-normal);
  margin-top: 4rem;
  padding: 0;
  font-weight: bold;

  svg {
    margin-right: var(--size-s);
  }
}
