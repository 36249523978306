.property-selector {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 14rem;

  button {
    background-color: var(--color-white);
    color: var(--color-black);
    border: 1px solid var(--border-color);
    padding: var(--size-s) var(--size-xl);
    border-radius: var(--border-radius-large);

    svg {
      margin-left: auto;
    }
  }

  &-name {
    font-size: var(--font-size-small);
    margin-bottom: var(--size-s);
  }
}

.property-list {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 14rem;
  overflow: hidden;
  background-color: var(--color-nitor-blue);
  color: var(--color-white);
  position: absolute;
  top: 2.2rem;
  border: 1px solid var(--border-color);
  border-top: none;
  z-index: var(--z-index-top);
  list-style: none;
  padding-inline-start: 0;

  li {
    cursor: pointer;
    padding-top: var(--size-s);
    padding-bottom: var(--size-s);
    padding-left: var(--size-m);
    transition: background-color var(--transition-quick) var(--ease-function);

    &:hover {
      background-color: var(--color-light-blue);
    }
  }
}
